import React from 'react';

// External Component
import { Box, Paragraph, Heading, Section } from '@thepuzzlers/pieces';

// Local Components
import { IllustrationHeadline, HorizontalLine } from 'components';

// Helpers
import { convertStringToSlug } from 'helper';

// Animation
import { menuCategory } from '../animations';

// Hooks
import { useFormatPrice } from 'hooks';

// Helper function
import { convertApiHtmlText } from 'boxenStopHooks';

export const MenuCategory = ({ data: { bgColor }, menu, scroller }) => {
  const { name, dishes, image } = menu;
  const getSectionId = () => `menu-category__${convertStringToSlug(name)}`;

  return (
    <Section id={getSectionId()} variants={menuCategory}>
      <IllustrationHeadline
        categoryName={name}
        image={image.image}
        bgColor={bgColor}
        scroller={scroller}
      />
      <DishesWrapper dishes={dishes} />
    </Section>
  );
};

const DishesWrapper = ({ dishes }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '2 / span 10',
          '4 / span 18',
          '1/25',
          '2 / span 22',
          '2 / span 22'
        ],
        mt: ['4rem', '4.8rem', '4rem', '4rem', '4.8rem', '4.8rem']
      }}>
      <HorizontalLine isBold />
      {dishes.map((dish, index) => (
        <DishItem key={index} dish={dish} />
      ))}
    </Box>
  );
};

const DishItem = ({ dish }) => {
  const formatPrice = useFormatPrice();
  const { name, description, prices } = dish.data;

  const getDishPrice = () =>
    prices.reduce((acc, cur, index) => {
      return (
        acc +
        `${index !== 0 ? ' /' : ''} ${formatPrice(cur.price)} ${cur.title}`
      );
    }, '');

  return (
    <Box
      sx={{
        ':first-of-type': {
          mt: ['4rem', '4.8rem', '4rem', '4rem', '4.8rem', '4.8rem']
        },
        ':not(:first-of-type)': {
          mt: ['4.8rem', '5.6rem', '6.4rem', '4.8rem', '6.4rem', '6.4rem']
        },
        width: [null, null, '46.8rem', '63rem', '83.2rem', '106.3rem'],
        display: [null, null, null, 'grid', 'grid', 'grid'],
        mx: [null, null, null, 'auto', 'auto', 'auto'],
        gridTemplateColumns: [
          null,
          null,
          null,
          '25.3rem 1fr',
          '36.9rem 1fr',
          '47rem 1fr'
        ],
        columnGap: [null, null, null, '3.7rem', '9.3rem', '12.2rem']
      }}>
      <Heading
        as="h3"
        sx={{
          fontFamily: 'primary.bold',
          linHeight: 1.25,
          fontSize: ['2.2rem', '2.6rem', '2.6rem', '2rem', '2.4rem', '2.8rem']
        }}>
        {name}
      </Heading>
      <Box
        sx={{
          mt: ['0.8rem', '0.8rem', '0.8rem', 0, 0, 0]
        }}>
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: convertApiHtmlText(description.html)
          }}
          sx={{
            lineHeight: 1.5,
            fontSize: [
              '1.5rem',
              '1.7rem',
              '1.7rem',
              '1.5rem',
              '1.6rem',
              '1.8rem'
            ]
          }}
        />
        <Paragraph
          sx={{
            lineHeight: 1.5,
            fontSize: [
              '1.5rem',
              '1.6rem',
              '1.7rem',
              '1.5rem',
              '1.6rem',
              '1.8rem'
            ],
            mt: ['2.4rem', '2.4rem', '2.4rem', '1.6rem', '1.6rem', '2.4rem'],
            fontFamily: 'body.medium'
          }}>
          {getDishPrice()}
        </Paragraph>
      </Box>
    </Box>
  );
};
