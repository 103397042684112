import React from 'react';

// External Cmponents
import { GridWrapper, Heading, Box, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { GridSpacer, DownArrow } from 'components';
import { partnerSlideUpAnimation, arrowElementSlideDown } from '../animations';

export const Partners = ({ headline, partners }) => {
  return (
    <GridWrapper
      variants={partnerSlideUpAnimation}
      sx={{ position: 'relative' }}>
      <TopSpacer />
      <Headline headline={headline} />
      <PartnersBlock partners={partners} />
      <Decoration />
    </GridWrapper>
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '4 / span 18',
          '1 / span 9',
          '1 / span 11',
          '2 / span 10'
        ],
        fontFamily: 'primary.bold',
        lineHeight: 1.25,
        fontSize: ['3rem', '4rem', '4.8rem', '3.6rem', '4.8rem', '5.2rem'],
        mt: ['4.9rem', '10.9rem', '10.5rem', 0, 0, 0],
        '& > span': {
          fontFamily: 'primary.italic'
        }
      }}
      dangerouslySetInnerHTML={{ __html: headline }}
    />
  );
};

const PartnersBlock = ({ partners }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '4 / span 18',
          '12/25',
          '14/25',
          '13 / span 11'
        ],
        gridRow: [null, null, null, '2 / span 2', '2 / span 2', '2 / span 2'],
        mt: ['3.2rem', '3.2rem', '4rem', 0, 0, 0]
      }}>
      {partners.map((partner, index) => (
        <PartnerItem key={index} partner={partner} />
      ))}
    </Box>
  );
};

const PartnerItem = ({ partner: { name, location, website } }) => {
  const partnerWebsite = website
    ? `• <a href=${`https://${website}`} target="__blank" >${website}</a>`
    : '';
  return (
    <Paragraph
      sx={{
        linHeight: 1.5,
        fontSize: ['1.5rem', '1.6rem', '1.7rem', '1.5rem', '1.7rem', '1.8rem'],
        '& > a:visited': {
          color: 'text'
        },
        ':not(:first-of-type)': {
          mt: ['1.6rem', '1.2rem', '1.2rem', '0.8rem', '1.6rem', '1.6rem']
        }
      }}
      dangerouslySetInnerHTML={{
        __html: `${name} • ${location} ${partnerWebsite}`
      }}
    />
  );
};

const TopSpacer = () => (
  <GridSpacer
    height={[null, null, null, '4.3rem', '6.4rem', '9.9rem']}
    sx={{
      display: ['none', 'none', 'none', 'block', 'block', 'block']
    }}
  />
);

const Decoration = () => {
  return (
    <DownArrow
      variants={arrowElementSlideDown}
      sx={{
        gridColumn: [
          '12/13',
          '12/13',
          '1 / span 2',
          '1 / span 1',
          '1 / span 1',
          '2 / span 1'
        ],
        gridRow: [null, null, null, 3, 3, 3],
        width: '1.5rem',
        mt: ['2.4rem', '5rem', 0, 0, 0, 0],
        alignSelf: [null, null, null, 'end', 'end', 'end'],
        justifySelf: 'center'
      }}
    />
  );
};
