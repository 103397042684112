import { colors } from 'gatsby-plugin-theme-ui/colors';

const transition = {
  type: 'tween',
  duration: 0.7,
  ease: [0.87, 0, 0.13, 1]
};

// Overlay Container animation
export const containerSlideDownAnimation = {
  initial: {
    // height: 0,
    background: `linear-gradient(to bottom, ${colors.background} 0% , ${colors.background} 0%, transparent 0%)`,
    transition
  },
  animate: {
    // height: '100%',
    background: `linear-gradient(to bottom, ${colors.background} 0% , ${colors.background} 100%, transparent 100%)`,
    transition: {
      ...transition,
      staggerChildren: 0.5
    }
  },
  exit: {
    background: `linear-gradient(to bottom, ${colors.background} 0% , ${colors.background} 0%, transparent 0%)`,
    transition: {
      ...transition,
      when: 'afterChildren',
      duration: 0.4
    }
  }
};

export const closeButtonFadeUp = {
  initial: {
    opacity: 0,
    transition
  },
  animate: {
    opacity: 1,
    transition
  },
  exit: {
    opacity: 0,
    transition
  }
};

export const partnerSlideUpAnimation = {
  initial: {
    y: '30rem',
    opacity: 0,
    transition
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ...transition,
      // delaying the arrow element
      delayChildren: 0.3
    }
  },
  exit: {
    y: '30rem',
    opacity: 0,
    transition
  }
};

export const arrowElementSlideDown = {
  initial: {
    y: '-20rem',
    opacity: 0,
    transition
  },
  animate: {
    y: 0,
    opacity: 1,
    transition
  },
  exit: {
    y: '-20rem',
    opacity: 0,
    transition
  }
};

export const menuCategory = {
  initial: {
    y: '20rem',
    opacity: 0,
    transition
  },
  animate: {
    y: 0,
    opacity: 1,
    transition
  },
  exit: {
    y: '20rem',
    opacity: 0,
    transition
  }
};
