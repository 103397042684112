import React from 'react';

// External Components
import { GridWrapper } from '@thepuzzlers/pieces';
import { navigate } from '@reach/router';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { WideCloseButton } from 'components';

// Animation
import { closeButtonFadeUp } from '../animations';

export const Header = () => {
  function handleClick() {
    const currentPath = sessionStorage.getItem('current-path');

    return !currentPath ? navigate('/') : navigate(-1);
  }

  return (
    <GridWrapper
      sx={{
        py: ['1.7rem', '2.3rem', '2.2rem', '1.3rem', '2.3rem', '2.7rem']
      }}>
      <WideCloseButton
        variants={closeButtonFadeUp}
        onClick={handleClick}
        sx={{
          justifySelf: 'end',
          gridColumn: ['9/13', '10/13', '21/25', '22/25', '22/25', '22/25'],
          width: ['7.8rem', '8.9rem', '8.9rem', '7.8rem', '6.9rem', '9rem']
        }}
      />
      {/* Hidden link for google crawlers */}
      <NavigationLink
        to="/"
        sx={{ position: 'absolute', visibility: 'hidden' }}>
        Zurück
      </NavigationLink>
    </GridWrapper>
  );
};
